<template>
  <div class="_wrap">
    <div class="_swiper">
      <el-carousel height="960px">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <img :src="$IMG_URL + item.cover" alt="">
        </el-carousel-item>
      </el-carousel>


      <div class="_sw_nr">
        <div class="_left">

          <div class="_qr">
            <img :src="$IMG_URL + detail.fzq_oa_qrcode" alt="">
            <div class="_p">
              <p>关注公众号</p>
              <p>即刻预约参观</p>
            </div>
          </div>

          <div class="_time">

            <p>
              <span>开放时间</span>
              <span>({{ detail.stop_time }})</span>
              <span>停止入场</span>
            </p>

            <p class="_sj">{{ detail.open_time }}</p>

            <p>周一闭馆,法定节假日除外</p>
            <p>
              <span>联系电话：</span>
              <span class="_tel">{{ detail.contact_tel }}</span>
            </p>
          </div>

        </div>
        <div class="_mid">
          <span class="_shu"></span>
          <img src="@/assets/images/ly.png" alt="">
          <span class="_shu"></span>
        </div>
        <div class="_right">
          <div class="_p" v-html="detail.content">

          </div>
        </div>
      </div>
    </div>

    <div class="_baseInfo1" v-if="!seen">

      <div class="_auto">
        <div class="_tt1">
          <div class="_text1">
            <p>场馆开放时间</p>
            <p class="_tm">{{ detail.open_time }}</p>
          </div>

          <div class="_text2">
            <p><span class="_sp1">{{ detail.stop_time }}</span><span>后停止入馆</span></p>
            <p>周一闭馆,法定节假日除外</p>
          </div>
        </div>

        <div class="_tt2">
          <span>官方联系电话</span>
          <!-- <span class="_sp1">{{ detail.contact_tel }}</span> -->
          <span class="_sp1">
            <a :href="'tel:' + detail.contact_tel" style="color: #EED798">{{ detail.contact_tel }}</a>

          </span>

       
        </div>


        <div class="_tt3">
          <div class="_tit">
            <img src="../../assets/images/phone/fzg.png" alt="">
          </div>

          <div class="_html" v-html="detail.content">

          </div>
        </div>

      </div>

    </div>

    <div class="_g_wrap">
      <div class="_g_auto">
        <h4></h4>
        <div class="swiper-container " ref="floorSwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in  swList " :key="index" style="cursor: pointer;">

              <div class="_li">

                <div class="_li_l">
                  <img class="_order" :src="item.order" alt="">

                </div>

                <div class="_li_r">
                  <div class="_img">
                    <img class="_img_t" :src="$IMG_URL + item.cover" alt="">
                  </div>
                  <div class="_h4">

                    <p>{{ item.description }}</p>
                  </div>
                </div>


              </div>


            </div>

          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>

        </div>

        <!-- 如果需要导航按钮 -->
        <div class="_anniu">
          <div class="prev">
            <img v-if="seen" src="@/assets/images/left.png" alt="" @click="prev">
            <img v-if="!seen" src="@/assets/images/phone/l.png" alt="" @click="prev">

          </div>
          <div class="next">
            <img v-if="seen" src="@/assets/images/right.png" alt="" @click="next">
            <img v-if="!seen" src="@/assets/images/phone/r.png" alt="" @click="next">

          </div>

        </div>

      </div>
    </div>


    <div class="_dtdl">
      <h4></h4>

      <div class="_img">
        <img :src="seen ? dlImg : p_dlImg" alt="">
      </div>

    </div>


    <div class="_jjfw">
      <h4></h4>
      <div class="_cont">
        <div class="_left">
          <div class="_html" ref="myHtml" v-html="detail.explaintion_service"></div>
        </div>
        <div class="_right">
          <img src="@/assets/images/Rectangle 44.png" alt="">
          <p>微信扫码 · 线上讲解</p>
        </div>
      </div>
    </div>


    <div class="_ylxz">
      <h4></h4>
      <div class="_cont">
        <div style="padding: 40px;" class="_html" ref="myHtml1" v-html="detail.notice"></div>
      </div>
    </div>


    <fixIcon />
    <coment ref="comentRef"></coment>
    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { getDataById } from '../../api/getData'
export default {
  data() {
    return {
      dlImg: require("@/assets/images/dldt.png"),
      p_dlImg: require("@/assets/images/phone/dld.png"),
      banner: [],
      detail: {},
      swiper: null,
      swList: [
        {
          url: require("@/assets/images/cs.png"),
          order: require("@/assets/images/0.png"),
          text: "洛阳自古就有“天下之中”的美誉，《史记·周本纪》中就有“此天下之中,四方入贡道里均”的记载。洛阳自然条件优越,古人称之为“河山控戴,形胜甲于天下”,历史上先后有13个朝代以此为都作为全国政治、经济、文化中心长达1500余年，是丝绸之路的东端起点和隋唐大运河的中心。"

        },
        {
          url: require("@/assets/images/cs.png"),
          order: require("@/assets/images/1.png"),
          text: "洛阳自古就有“天下之中”的美誉，《史记·周本纪》中就有“此天下之中,四方入贡道里均”的记载。洛阳自然条件优越,古人称之为“河山控戴,形胜甲于天下”,历史上先后有13个朝代以此为都作为全国政治、经济、文化中心长达1500余年，是丝绸之路的东端起点和隋唐大运河的中心。"
        },
        {
          url: require("@/assets/images/cs.png"),
          order: require("@/assets/images/2.png"),
          text: "洛阳自古就有“天下之中”的美誉，《史记·周本纪》中就有“此天下之中,四方入贡道里均”的记载。洛阳自然条件优越,古人称之为“河山控戴,形胜甲于天下”,历史上先后有13个朝代以此为都作为全国政治、经济、文化中心长达1500余年，是丝绸之路的东端起点和隋唐大运河的中心。"
        },
        {
          url: require("@/assets/images/cs.png"),
          order: require("@/assets/images/3.png"),
          text: "洛阳自古就有“天下之中”的美誉，《史记·周本纪》中就有“此天下之中,四方入贡道里均”的记载。洛阳自然条件优越,古人称之为“河山控戴,形胜甲于天下”,历史上先后有13个朝代以此为都作为全国政治、经济、文化中心长达1500余年，是丝绸之路的东端起点和隋唐大运河的中心。"
        },
        {
          url: require("@/assets/images/cs.png"),
          order: require("@/assets/images/4.png"),
          text: "洛阳自古就有“天下之中”的美誉，《史记·周本纪》中就有“此天下之中,四方入贡道里均”的记载。洛阳自然条件优越,古人称之为“河山控戴,形胜甲于天下”,历史上先后有13个朝代以此为都作为全国政治、经济、文化中心长达1500余年，是丝绸之路的东端起点和隋唐大运河的中心。"
        },
        {
          url: require("@/assets/images/cs.png"),
          order: require("@/assets/images/5.png"),
          text: "洛阳自古就有“天下之中”的美誉，《史记·周本纪》中就有“此天下之中,四方入贡道里均”的记载。洛阳自然条件优越,古人称之为“河山控戴,形胜甲于天下”,历史上先后有13个朝代以此为都作为全国政治、经济、文化中心长达1500余年，是丝绸之路的东端起点和隋唐大运河的中心。"
        },

      ],
      seen: true,

    }
  },
  async mounted() {
    this.menuSeen()
    await this.getBanner()
    await this.getFzg()
    await this.getZt()

    this.initSwiper()

    window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
    })

    // this.$scrollTop()
  },

  watch: {
    $route(to, from) {
      console.log(to);


  
      this.$scrollTop()
    }
  },
  methods: {

    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    initSwiper() {

      if (this.seen) {
        this.swiper = new Swiper('.swiper-container', {
          loop: true,
          slidesPerView: 3,
          spaceBetween: 80,
          slidesPerGroup: 3,

          loopFillGroupWithBlank: true,

          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            dynamicMainBullets: 2,
            clickable: true,
          },
        })
      } else {
        this.swiper = new Swiper('.swiper-container', {
          loop: true,
          slidesPerView: 1,
          spaceBetween: 80,

          loopFillGroupWithBlank: true,

          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            dynamicMainBullets: 2,
            clickable: true,
          },
        })
      }




    },

    prev() {
      this.swiper.slidePrev()
    },
    next() {
      this.swiper.slideNext()
    },


    // 方志馆信息
    async getFzg() {
      const params = {
        cate_id: 35,
        page: 1,
        page_num: 10,
        keywords: '',
        type_id: '',
        times: '',
      }
      await getDataById(params).then(res => {
        console.log(res)
        this.detail = res.data




        let reg1 = new RegExp("nowrap;", "g")

        let a = this.detail.explaintion_service.replace(reg1, 'wrap')
        console.log(a)
        this.detail.explaintion_service = a

      })
    },
    // 展厅介绍
    async getZt() {
      const params = {
        cate_id: 36,
        page: 1,
        page_num: 10,
        keywords: '',
        type_id: '',
        times: '',
      }
      await getDataById(params).then(res => {
        console.log(res)
        // this.detail = res.data
        let list = res.data.data

        this.swList = list

        for (let i in this.swList) {
          let a = require(`@/assets/images/${i}.png`)
          this.$set(list[i], 'order', a)


        }

        console.log(this.swList)

      })
    },

    // banner

    async getBanner() {
      const params = {
        cate_id: 37,
        page: 1,
        page_num: 10,
        keywords: '',
        type_id: '',
        times: '',
      }
      await getDataById(params).then(res => {
        console.log(res)
        this.banner = res.data.data


      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

@mixin tit($url)
{
  width: 299px;
  height: 52px;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

._wrap
{
  width: 100%;
  background-color: #F8F5EE;
}

._swiper
{
  position: relative;

  ._sw_nr
  {

    background: rgba(28, 32, 39, 0.85);
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%;
    height: 198px;
    color: #E1CF9E;
    padding: 23px 50px;
    display: flex;
    justify-content: space-between;

    ._left
    {
      display: flex;
      width: 36%;

      ._qr
      {
        width: 315px;
        height: 151px;
        border-radius: 4px;
        border: 1px solid rgba(255, 207, 136, 0.4);
        display: flex;
        align-items: center;
        margin-right: 25px;

        img
        {
          width: 135px;
          height: 135px;
          margin-left: 10px;
          margin-right: 20px;

        }

        ._p
        {
          p
          {
            font-size: 20px;
          }
        }
      }

      ._time
      {
        p
        {
          font-size: 22px;

          span
          {
            font-size: 22px;
          }

          ._tel
          {
            font-weight: bold;
          }
        }

        ._sj
        {
          font-size: 44px;
          font-weight: bold;
        }

        @media (max-width:1880px)
        {
          ._sj
          {
            font-size: 32px;
          }
        }
      }


    }

    ._mid
    {

      ._shu
      {
        display: inline-block;
        width: 1px;
        height: 151px;
        background: linear-gradient(180deg, rgba(225, 207, 158, 0) 0%, rgba(225, 207, 158, 0.63) 53%, rgba(225, 207, 158, 0) 100%);

      }

      img
      {
        margin: 0 30px;
      }
    }

    ._right
    {
      width: 56%;

      ._p
      {
        text-indent: 2em;
        height: 100%;
        overflow-y: auto;
        line-height: 30px;
      }
    }
  }
}

._g_auto
{
  width: 85%;
  margin: 0 auto;
  position: relative;

  h4
  {
    @include tit("../../assets/images/h4-21.png");

    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 100px;
  }

  .swiper-slide
  {

    ._li
    {
      position: relative;
      // width: 125%;
      display: flex;

    
      ._li_l{
        margin-right: 10px;
  
      }
    }

    ._img
    {
      width: 410px;
      height: 277px;
      overflow: hidden;
      // position: absolute;
      // right: 0;

      ._img_t
      {
        width: 100%;
        height: 100%;
        transition: all .5s;
      }

      ._img_t:hover
      {

        transform: scale(1.1);
      }
    }



    ._h4
    {
      width: 410px;
      text-indent: 2em;
      height: 226px;
      background-image: url("../../assets/images/ztbg.png");
      line-height: 28px;
      overflow: hidden;
      color: #413B32;
      // position: absolute;
      // right: 0;
      // top: 277px;

      p
      {
        padding: 20px;
      }
    }
  }
}


._anniu
{
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50px;
  // background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // z-index: 10;

  .prev
  {
    margin-left: -90px;
    cursor: pointer;
  }

  .next
  {
    margin-right: -90px;
    cursor: pointer;
  }
}


._dtdl
{
  h4
  {
    @include tit("../../assets/images/h4-22.png");
    margin: 0 auto;
    margin-bottom: 60px;
    margin-top: 100px;
  }

  ._img
  {

    // padding: 0 50px;
    width: 95%;
    margin: 0 auto;

    img
    {
      width: 100%;
    }
  }
}


._jjfw
{
  h4
  {
    @include tit("../../assets/images/h4-23.png");
    margin: 0 auto;
    margin-bottom: 60px;
    margin-top: 100px;
  }

  ._cont
  {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    ._left
    {
      width: 70%;
      min-height: 230px;
    }

    ._right
    {
      width: 170px;
      height: 200px;
      background: #A5322B;
      border: 1px solid #A5322B;
      text-align: center;

      img
      {
        width: 149px;
        height: 149px;
        margin-top: 10px;

      }

      p
      {
        color: white;
        font-weight: bold;
        margin-top: 7px;
      }
    }
  }
}


::v-deep ._jjfw{
  @include respondTo("phone"){
    ._html{
      p{
        text-wrap: wrap !important;
      }
      span{
        text-wrap: wrap !important;
      }
    }
  }
}


._ylxz
{
  margin-top: 100px;

  h4
  {
    @include tit("../../assets/images/h4-24.png");
    margin: 0 auto;
    margin-bottom: 40px;

  }

  ._cont
  {
    width: 85%;
    margin: 0 auto;

    height: 684px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    overflow-y: auto;
    border: 1px solid #D8D0C5;

  }
}







.swiper-container
{

  --swiper-pagination-color: #AF9972;
  /* 两种都可以 */
}

.swiper-container
{
  height: 560px;
}




::v-deep .swiper-button-prev,
.swiper-container-rtl .swiper-button-next
{
  left: 0px;
}





._p::-webkit-scrollbar
{
  width: 8px;
  height: 10px;
  background-color: transparent;
}

._p::-webkit-scrollbar-thumb
{
  border-radius: 20px;
  background-color: rgba(144, 147, 153, .3);
}


._wrap
{
  @include respondTo("phone")
  {
    background-color: #fff;
  }
}

::v-deep ._swiper
{
  @include respondTo("phone")
  {
    .el-carousel__container
    {
      height: 223px !important;

      img
      {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    ._sw_nr
    {
      display: none;
    }
  }

}

._baseInfo1
{
  margin-top: -15px;
  position: relative;
  z-index: 10;
  font-size: 14px;

  ._auto
  {
    padding: 0 8px;
  }

  ._tt1
  {
    width: 100%;
    height: 104px;
    background-image: url("../../assets/images/i1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
    padding: 30px 10px 20px 10px;

    ._text1
    {
      color: #E1CF9E;

      ._tm
      {
        color: #EED798;
        font-size: 22px;
        font-weight: bold
      }

    }

    ._text2
    {
      color: #E1CF9E;

      ._sp1
      {
        color: #EED798;
        font-size: 22px;
        font-weight: bold
      }
    }
  }

  ._tt2
  {
    width: 100%;
    height: 40px;
    background-image: url("../../assets/images/i2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    color: #E1CF9E;
    display: flex;
    align-items: center;
    justify-content: center;

    ._sp1
    {
      color: #EED798;
      font-size: 20px;
      font-weight: bold;
      margin-left: 10px;
    }
  }

  ._tt3
  {
    ._tit
    {
      text-align: center;
      margin-top: 20px;

      img
      {
        width: 178px;
        height: 31px;
      }

    }

    ._html
    {
      margin-top: 16px;
      text-indent: 2em;
      line-height: 30px;
    }
  }

}

._g_wrap
{
  @include respondTo("phone")
  {
    ._g_auto
    {
      width: 95%;

      >h4
      {
        background-image: url("../../assets/images/phone/ztjs.png");
        width: 173px;
        height: 30px;
        margin-bottom: 20px;
        margin-top: 30px;
      }

      .swiper-container
      {
        height: auto;

        .swiper-slide
        {
          ._img
          {
            width: 100%;
            height: 242px;
            position: initial;
          }

          ._li_l{
            position: absolute;
            top: 260px;
            left: 10px;
          }

          ._h4
          {
            width: 100%;
            height: 360px;
            overflow-y: scroll;
            position: initial;

            p
            {
              margin-left: 80px;
            }
          }

          ._order
          {
            
          }
        }
      }

      ._anniu
      {
        top: 92%;
        z-index: 10;

        .prev
        {
          margin-left: 20px;
          text-align: center;

          img
          {
            width: 38px;
          }
        }

        .next
        {
          margin-right: 20px;
          text-align: center;

          img
          {
            width: 38px;
          }

        }
      }
    }
  }
}


._dtdl
{
  @include respondTo('phone')
  {
    >h4
    {
      background-image: url("../../assets/images/phone/dtdl.png");
      width: 173px;
      height: 30px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

._jjfw
{
  @include respondTo('phone')
  {
    >h4
    {
      background-image: url("../../assets/images/phone/jjfw.png");
      width: 173px;
      height: 30px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    ._cont
    {
      width: 95%;
      display: block;

      ._left
      {
        width: 100%;
        min-height: auto;
      }

      ._right
      {
        // display: none;
        margin: 0 auto;
      }
    }
  }
}

._ylxz
{
  @include respondTo('phone')
  {
    margin-top: 0;
    margin-bottom: 30px;

    >h4
    {
      background-image: url("../../assets/images/phone/ylxz.png");
      width: 173px;
      height: 30px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    ._cont
    {
      width: 95%;

      ._html
      {
        padding: 10px !important;
      }
    }
  }
}
</style>